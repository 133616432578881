import React, { useEffect, Suspense, lazy } from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import { Nav,Bot,Section,Icon,Photo,Text,AnimatedText,Space } from '../Symbols.js'

export default (props)=>{
    useEffect(() => {
    // This ensures a refresh of the page so that the google captcha works correctly
      if (window.location.search != "?signup") {
          window.location.replace("/start/?signup")
      } else {
        document.getElementById("xA1").classList.remove("freeze")
      }
    }, ["/start/"]);
    return <>
        <Helmet>
            <script src="https://emailoctopus.com/bundles/emailoctopuslist/js/1.6/form-recaptcha.js" />
            <script src="https://emailoctopus.com/bundles/emailoctopuslist/js/1.6/form-embed.js" />
        </Helmet>
        <Nav className="float"/>
        <Section id="xA1" className="freeze page center noBotPad" wrapperclassName="group">
            <Space h="64"/>
            <Icon src="person-add.svg" w="96" h="96" className="standby move-down delay1"/>
            <Space h="32"/>
            <Text className="ready standby subtle-in delay2 dusk">Fall 2022</Text>
            <div className="slim">
                <AnimatedText
                    text="Join our Exclusive Launch Program"
                    size = "XXXL"
                    class = "accentFont blue"
                />
            </div>

            <Space h="32"/>
            <Text size="L" className="standby fade-in skinny delay2 dusk">Add your email to our waitlist and get notified when you're accepted.</Text>
            <Space h="16"/>


            <div class="emailoctopus-form-wrapper emailoctopus-form-centered null" >
                <Text className="green" size="XL"><p class="emailoctopus-success-message"></p></Text>
                <Text className="red"><p class="emailoctopus-error-message"></p></Text>
                <Space h="16"/>
                <form
                    action="https://emailoctopus.com/lists/88a624cb-d9f4-11eb-96e5-06b4694bee2a/members/embedded/1.3s/add"
                    method="post"
                    data-message-success="Great, you're on the list!"
                    data-message-missing-email-address="Your email address is required."
                    data-message-invalid-email-address="Your email address looks incorrect, please try again."
                    data-message-bot-submission-error="Sorry, this response looks like spam."
                    data-message-consent-required="Please check the checkbox to indicate your consent."
                    data-message-invalid-parameters-error="This form has missing or invalid fields."
                    data-message-unknown-error="Sorry, an unknown error has occurred. Please try again later."
                    className="group emailoctopus-form"
                    data-sitekey="6LdYsmsUAAAAAPXVTt-ovRsPIJ_IVhvYBBhGvRV6"
                >
                    <div class="emailoctopus-form-row">
                        <input className="ready standby fade-in delay2" id="field_0" name="field_0" type="email" placeholder="Enter your email" required="required" />
                    </div>
                    <div class="emailoctopus-form-row">
                        <input className="ready standby fade-in delay3" id="field_1" name="field_1" type="text" placeholder="First name" />
                    </div>
                    <div class="emailoctopus-form-row">
                        <input className="ready standby fade-in delay4" id="field_2" name="field_2" type="text" placeholder="Last name" />
                    </div>
                    <div aria-hidden="true" class="emailoctopus-form-row-hp">
                        <input style={{display:"none"}} type="text" name="hpc4b27b6e-eb38-11e9-be00-06b4694bee2a" tabindex="-1" autocomplete="nope" />
                    </div>
                    <div class="emailoctopus-form-row-subscribe"className="ready standby fade-in delay5" >
                        <input type="hidden" name="successRedirectUrl" />
                        <button type="submit" className="blue button">
                            <div className="flex row">
                                <Icon w="24" h="24" src="plus-white.svg" style={{transform:"translateX(-6px)"}}/>
                                <Text>Join limited waitlist</Text>
                            </div>
                        </button>
                    </div>
                    <Space h="32"/>
                    <Text className="ready standby subtle-in delay5 gray" size="S" style={{maxWidth:"280px"}}>By submitting this form, you agree to our <Link to="/privacypolicy/">privacy policy</Link>.</Text>
                    <Space h="32"/>
                </form>
            </div>


        </Section>
        <Section className="center black">

            <Text className="standby">About us</Text>
            <Space h="16"/>
            <div className="slim">
                <AnimatedText
                    className="zoom"
                    text="The Interpreting App is here to support the deaf community."
                    size = "XXL"
                />
            </div>
            <Space h="32"/>
            <div>
                <Link to="/about/" className="standby button blue">
                    <Text>Learn more</Text>
                </Link>
            </div>

            <Photo className="background" src="img7.png"/>
    
        </Section>
        <Bot/>
    </>
}

import { Link } from 'react-router-dom';
import { Nav,Bot,Section,Space,Photo,Icon,Text,AnimatedText } from '../Symbols.js'
import { SecondarySignup } from '../Banners'

export default (props)=>{
    return <>
        <Nav light className="float" />
        <Section className="shortPage black center" style={{color:"white"}}>
            <Space h="64"/>
            <Icon w="96" h="96" src="covid.svg" className="standby ready move-down delay2" />
            <Space h="32"/>
            <div className="group flex" style={{zIndex:"1"}}>
                <Text size="XXL" className="narrow accentFont" style={{textShadow:"4px 8px 32px #224"}}>
                    <AnimatedText
                        className="ready"
                        text="Our response to Covid-19"
                        size = "XXL"
                    />
                </Text>
                <Space h="32"/>
                <Text className="ready standby fade-in delay2">
                    An extension to our safety procedures
                </Text>
            </div>
       </Section>
        <Section className="center">
            <Text className="standby ready subtle-in dusk" style={{transform:"translateY(-64px)"}}>
                Be sure to review the safety guidelines below.
            </Text>
            <Space h="16"/>
            <Text size="XL" className="standby">
                Communicating with masks during Coronavirus
            </Text>

            <Space h="64"/>
            <Photo src="masks.png" w="100%" h="512px" className="standby narrow shadow"/>
            <Space h="64"/>

            <div className="group slim flex top alignTextLeft">

                <Text className="standby indent">
                    It is very important to wear masks to stay safe during a pandemic, but also very important that deaf and hard of hearing people can understand what everyone is saying.
                </Text>
                <Space h="16"/>
                <Text className="standby">
                    These guidelines explain the best ways to communicate with deaf and hard of hearing people while following health recommendations.
                </Text>

                <Space h="32"/>

                <div className="flex left top">
                    <div className="flex row left standby">
                        <Icon w="32" h="32" src="check-blue.svg"/>
                        <Space w="16"/>
                        <Text size="L">Wear clear face shields or masks</Text>
                    </div>
                    <Space h="16"/>
                    <div className="flex row left standby">
                        <Icon w="32" h="32" src="check-blue.svg"/>
                        <Space w="16"/>
                        <Text size="L">Stand six feet apart at all times.</Text>
                    </div>
                    <Space h="16"/>
                    <div className="flex row left standby">
                        <Icon w="32" h="32" src="check-blue.svg"/>
                        <Space w="16"/>
                        <Text size="L">Stay home if you're feeling unwell.</Text>
                    </div>
                </div>

                <Space h="64"/>
                <Text>
                    1. Impact on on-site interpreter staff
                </Text>
                <Text>  
                    Our telephone and video remote interpretation options remain available to address the unusual spike in demand for interpreting services and the impact of COVID-19 on on-site interpreter staff. If you have not yet signed up for a phone or video interpreting service and would like to learn more, please contact us at <a href="mailto:book@interpreting.app">book@interpreting.app</a>
                </Text>
                <Space h="16"/>
                <Text>
                    2. Open Communication with Customers
                </Text>
                <Text>
                    We encourage you to contact <a href="mailto:processing@interpreting.app">processing@interpreting.app</a> for specific accommodation and other questions or concerns. Organizations should notify us if additional measures or other entry restrictions are implemented. This will free up extra cushion time for the interpreter, allowing enough time to move between bookings. If a client confirms a diagnosis of COVID19 and has direct contact with an interpreter, please  contact <a href="mailto:compliance@interpreting.app">compliance@interpreting.app</a> immediately. An in- house quality and compliance representative will contact  you to respond to the situation.
                </Text>
                <Space h="16"/>
                <Text>
                    3. Essential Business Decisions
                </Text>
                <Text>
                    The Interpreting App has carefully reviewed State & Federal guidelines and determined that the health care field, social services, and government/court interpretation services are “essential”. Medical, social, or governmental functions that involve communication with individuals with limited English proficiency may not continue without interpreting services.
                </Text>
                <Space h="16"/>
                <Text>
                    4. Required COVID19 Testing Questionnaire
                </Text>
                <Text>
                    We have distributed the COVID19 Testing Questionnaire required for all interpreters inquiring about recent illnesses and travels. The compliance team reviews each response and handles all issues individually. These survey responses will be uploaded to our system which you can access at any time.
                </Text>
                <Space h="16"/>
                <Text>
                    5. Mandatory Daily Temperature Check
                </Text>
                <Text>
                    All team members should check the temperature before starting their daily work. Team members may not work if the temperature is above 100 degrees Fahrenheit.
                </Text>
                <Space h="16"/>
                <Text>
                    6. Reporting
                </Text>
                <Text>
                    When The Interpreting App is notified that an  interpreter or staff member confirms a diagnosis of COVID- 19, we will work with public health authorities to verify contact  information and notify affected clients and individuals. For these issues, send an email to <a href="mailto:compliance@interpreting.app">compliance@interpreting.app</a> and ask the client to provide the desired contact information.
                </Text>
                <Space h="16"/>
                <Text>
                    7. Regular monitoring of updates
                </Text>
                <Text>
                    Our in-house quality and compliance advisors are monitoring the websites of the U.S. Centers for Disease Control and Prevention and the websites of State Health and Family Services for changes or updates to recommendations and protocols. We follow recommendations from applicable government agencies along with healthcare, public sector and other customer needs.
                </Text>
                <Space h="16"/>
                <Text>
                    8. Frequent Communication with Interpreters
                </Text>
                <Text>
                    We are in regular contact with our interpreters for general information and safety protocols and protocols specific to specific client facilities (such as admission restrictions and screening).
                </Text>
                <Space h="16"/>
                <Text>
                    9. Training on Safety Protocols
                </Text>
                <Text>
                    All interpreters and management staff are trained to follow CDC guidelines regarding handwashing/disinfectant use, face sneezing/cough etiquette, environmental cleaning, and addressing unsafe travel. We send frequent notifications using bulk mail, bulk text, and our company intranet system.
                </Text>
                <Space h="16"/>
                <Text>
                    10. Strict Enforcement of the “Stay At Home If Sick” Rule
                </Text>
                <Text>
                    For the very few staff and all interpreters we staff in our offices, we are strictly enforcing the “Stay At Home If Sick” rule to all staff. We have updated the rule to comply with the state curfew, which would extend the "fever-free" period to 72 hours (from 24 hours) and adding a minimum waiting period of 7 days after the first onset. As per CDC recommendations, team members do not require physician notes to stay home sick, helping prevent overwhelming health care facilities when demand is rising. If an interpreter is symptomatic when booking an interpreter, we welcome and encourage clients to send them home and contact us to report the situation.
                </Text>
                <Space h="16"/>
                <Text>
                    11. Strict Enforcement of Self-Quarantine
                </Text>
                <Text>
                    All team members (administrative staff or interpreters) must self-quarantine in accordance with CDC and state-ordered guidelines or 16 days, whichever is longer, if self-quarantined by a government official or healthcare provider, or if notified.
                </Text>
                <Space h="16"/>
                <Text>
                    12. Abandon unnecessary travel
                </Text>
                <Text>
                    We are discouraging unnecessary travel, including travel outside the home country of team members, cruises traveling to areas on level 2 or 3, or anyone traveling to other hotspots specified by the CDC or state government agencies forced to close for at least 16 days. We were originally putting dates into this, but decided to keep the travel restrictions open-ended until the COVID-19 crisis subsides.
                </Text>
                <Space h="16"/>
                <Text>
                    13. Distancing Requirements
                </Text>
                <Text>
                    In order to maintain minimum basic operations, all team members who will continue to work in our offices must adhere to the requirements of appropriate social distancing.
                </Text>
                <Space h="48"/>
                <Text>
                    <Text className="blue"><a href="https://www.nad.org/2020/08/26/communicating-with-masks-during-coronavirus/" target="noreferer">The National Association of the Deaf (NAD) developed these guidelines</a></Text> with support and input from other deaf, hard of hearing, and DeafBlind consumer advocacy organizations and subject matter experts.
                </Text>

            </div>


        </Section>
        <Bot />
    </>
}
import { Link } from 'react-router-dom';
import { Nav,Bot,Section,Space,Photo,Icon,Text,AnimatedText } from '../Symbols.js'
import { SecondarySignup } from '../Banners'

export default (props)=>{
    return <>
        <Nav />
        <Section className="page center">
            
            <Space h="96"/>
            
            <Text className="standby subtle-in delay2 blue">What drives us</Text>
            <div className="narrow">
                <AnimatedText
                    className="center"
                    text="How we plan to solve inequality in the deaf community"
                    size = "XXL"
                />
            </div>
            <Space h="64"/>
            <Photo src="about1.jpg" w="100%" h="512px" className="standby delay3"/>
            <Space h="64"/>
            <div className="group slim flex top alignTextLeft">
                <Text size="XL" className="standby">Thousands of hospitals.<br/>Little support.</Text>
                <Space h="16"/>
                <Text className="standby indent">
                    A recent study of the U.S. healthcare system showed deaf & HOH participants experienced communication difficulties and some described their experience with our health care system in the following words: "fear, mistrust, and frustration."
                </Text>
            </div>
            <Space h="64"/>
            <div className="group slim flex">
                <div className="standby group slim flex row top">
                    <div className="flex" style={{width:"32px"}}>
                        <Space h="8"/>
                        <Icon w="32" h="32" src="timeline-now.svg"/>
                        <div className="timelineLine blue"/>
                    </div>
                    <div className="group slim flex top alignTextLeft">
                        <Text size="L" className="blue">Today</Text>
                        <Text className="dusk">
                            Announce a beta program to help expedite the transition towards a newer peer-to-peer economy, which we believe to be the primary, but not exclusive, sustainable solution.
                        </Text>
                    </div>
                </div>
                <div className="standby group slim flex row top">
                    <div className="flex" style={{width:"32px"}}>
                        <Space h="8"/>
                        <Icon w="32" h="32" src="timeline-now.svg"/>
                        <div className="timelineLine blue"/>
                    </div>
                    <div className="group slim flex top alignTextLeft">
                        <Text size="L" className="blue">Fall 2021</Text>
                        <Text className="dusk">
                            Outreach to organizations by allowing The Interpreting App, LLC to be procured as the leading national sign language interpreter agency - improving the quality of health care and reducing disparities in the deaf & hard- of-hearing community.
                        </Text>
                    </div>
                </div>
                <div className="standby group slim flex row top">
                    <div className="flex" style={{width:"32px"}}>
                        <Space h="8"/>
                        <Icon w="32" h="32" src="timeline-later.svg"/>
                        <div className="timelineLine"/>
                    </div>
                    <div className="group slim flex top alignTextLeft">
                        <Text size="L" className="dusk">2025</Text>
                        <Text className="dusk">
                            In keeping with a fast growing technology company, all free cashflow is plowed back into R&D to drive down the costs and bring the follow on improving communication with deaf patients. When someone uses the app, they are actually helping pay for the development of future technologies such as Augemented Reality (AR), Virtual Reality (VR) and Sign-to-Text via Artificial Intelligence (AI).
                        </Text>
                    </div>
                </div>

            </div>

            <Space h="64"/>
            <Photo src="timelapse.png" w="100%" h="512px" className="standby"/>
            <Space h="64"/>

            <div className="group slim flex top alignTextLeft">
                <Text size="XL" className="standby">
                    Innovate and analyze new and existing technology.
                </Text>
                <Space h="16"/>
                <Text size="L" className="standby blue">
                    How?
                </Text>
                <Text className="standby indent">
                    A member can use the app to directly request a sign language interpreter by name, language, location, gender and more, within seconds.
                </Text>
                <Text size="L" className="standby blue">
                    Why?
                </Text>
                <Text className="standby indent">
                    By agreeing to facilitate an interpreter for your organization, you are providing your clients with the safest and most available solution today.
                </Text>
                <Text size="L" className="standby blue">
                    When?
                </Text>
                <Text className="standby indent">
                    Our knowledgeable production team will reach out to your organization if a customer is requesting an interpreter from our platform for their visit and you are not yet enrolled on the app.
                </Text>
                <Text size="L" className="standby blue">
                    Where?
                </Text>
                <Text className="standby indent">
                The procurement of public health projects should be tailored to all individuals in the U.S who request an interpreter, including the use of feedback from our users to provide exactly what's needed, seamlessly.
                </Text>

                <Space h="64"/>

                <div className="flex">
                    <Text size="XL" className="standby">42.9%</Text>
                    <Text className="standby">Not in U.S. labor force.</Text>
                </div>
                <Space h="32"/>
                <Text className="standby indent">
                    With society’s lack of understanding of the Deaf community comes challenges in other aspects of daily life for Deaf individuals: employment, higher education, healthcare, mental health services, emergency preparedness, technology, and government benefits.
                </Text>
                <Space h="32"/>
                <Text className="standby indent">
                    Greater growth in employment rates for deaf people is needed in order to narrow the employment gap between deaf and hearing people, and this is not happening yet.
                </Text>
            </div>

            <Space h="64"/>
            <Photo src="alone.png" w="100%" h="512px" className="standby"/>
            <Space h="64"/>

            <div className="slim">
                <Text size="standby XL">
                    "I remember feeling alone, even when around a lot of people, because of communication barriers..."
                </Text>
                <Space h="16"/>
                <Text className="standby left">
                    "...I knew that most people were not malicious and that communication barriers exist only because of limited exposure to deaf people and a lack of understanding.
                </Text>
                <Space h="16"/>
                <Text className="standby subtle-in delay2 dusk">Ben Soukup Jr.</Text>
            </div>
            <Space h="64"/>
            <div className="group slim flex top alignTextLeft">
                <Text className="standby indent">
                    Imagine trying to understand your diagnosis through brief notes on a piece of paper or on a monitor you cannot clearly see.
                </Text>
                <Text className="standby indent">
                    Interpretation services are essential for the deaf & HOH population to interact with the health community, but can be costly. That is why we decided to take the initiative as the most #1 most affordable agency for sign language interpreters in the nation, and also the highest paying agency for both new and experienced interpreters.
                </Text>
                <Text className="standby indent">
                    Advocating for telecommunications services and talking to policy makers about the need and importance of funding for these services can help increase access to medical services and health-related programs.
                </Text>
                <Space h="32"/>
                <Text size="XL" className="standby">The experience can be frustrating for all involved.</Text>
            </div>

        </Section>
        <Section className="gray">
            <div className="grid split2x512">
                <Photo src="together.png" className="standby fade-in" style={{minHeight:"512px"}}/>
                <div className="standby fade-in delay2 group flex top">
                    <Text className="blue">
                        Together We Can
                    </Text>
                    <Space h="16"/>
                    <Text size="XXL">
                        We're here to help.
                    </Text>
                    <Space h="16"/>
                    <Text className="dusk">
                        At The Interpreting App, we are proud of our community and our culture.
                    </Text>
                    <Space h="16"/>
                    <Text className="dusk">
                        We're here to help.
                    </Text>
                </div>
            </div>
        </Section>
        <SecondarySignup />
        <Bot />
    </>
}
import { Link } from 'react-router-dom';
import { Nav,Bot,Section,Photo,Icon,Space,Text } from '../Symbols.js'
import { ProgramLaunch } from '../Banners'

export default (props)=>{
    return <>
        <Nav className="float" />
        <Section className="center short gray flex">
            <Space h="128"/>
            <Text size="XXL">Privacy Policy</Text>
            
        </Section>
        <Section id="policy" wrapperClass="narrow justifyFont smallFont">
                
            <Text size="XL">The Interpreting App, LLC</Text>

            <Text>The Interpreting App, LLC operates the interpreting.app website, which provides the SERVICE. This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, the The Interpreting App website. If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at interpreting.app, unless otherwise defined in this Privacy Policy. Our Privacy Policy was created with the help of the Privacy Policy Template and the Disclaimer Generator.</Text>

            <Text size="XL">Information Collection and Use</Text>

            <Text>For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect will be used to contact or identify you.</Text>

            <Text size="XL">Log Data</Text>

            <Text>We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer’s Internet Protocol ("IP") address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</Text>

            <Text size="XL">Google Analytics</Text>

            <Text>This website uses google analytics, you can learn more about their service's privacy policy, cookie policy, and more, <a target="noreferer" href="http://www.google.com/policies/privacy/partners/">here</a>.</Text>
            
            <Text size="XL">Cookies</Text>

            <Text>Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive. Our website uses these "cookies" to collection information and to improve our Service. If you choose to refuse our cookies, you may not be able to use some portions of our Service.</Text>

            <Text size="XL">Service Providers</Text>

            <Text>We may employ third-party companies and individuals due to the following reasons: To facilitate our Service; To provide the Service on our behalf; To perform Service-related services; or To assist us in analyzing how our Service is used. We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</Text>

            <Text size="XL">Security</Text>

            <Text>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</Text>

            <Text size="XL">Links to Other Sites</Text>

            <Text>Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</Text>

            <Text size="XL">Children's Privacy</Text>

            <Text>Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</Text>

            <Text size="XL">Changes to This Privacy Policy</Text>

            <Text>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</Text>

        </Section>
        <Section className="center gray" wrapperClass="narrow">
            <Text size="XL">If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</Text>
            <Space h="32"/>
            <div>
                <Link to="/contact/" className="button blue"><Text>View contact information</Text></Link>
            </div>
            <Space h="16"/>
        </Section>
        <style>{`
            #policy .text.XL {
                margin-top: 16px;
            }
            #nav .button {
                background: var(--white);
            }
            #nav #navToggle {
                background: var(--white);
            }
        `}</style>
        <Bot/>
    </>
}
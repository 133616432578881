import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';
import { Nav,Bot,Section,Photo,Icon,Space,Text,AnimatedText } from './Symbols.js'
import { TiltedPhone } from './Icons'

export function FeatureHeader(props) {
    return <Section id="hero" className="blue" style={{background:"linear-gradient(160deg, var(--blue) 68%, var(--blue-light) 88%)"}}>
    <div className="group tagline">
        <Text size="XXXL" className="accentFont" style={{maxWidth:"640px"}}>
            <AnimatedText
                className="ready zoom"
                text={props.title}
                size = "XXXL"
                class = "accentFont"
            />
        </Text>
    </div>
    <div className="group buttonArea">
        <a href="/start/" className="ready standby fade-in delay2 button">
            <div className="flex row">
                <Icon w="24" h="24" src="plus-black.svg" style={{transform:"translateX(-6px)"}}/>
                <Text>Join limited waitlist</Text>
            </div>
        </a>
    </div>
    <div id="phone-background" className="flex">
        <div id="ui-transform">
            {props.children}
        </div>
        <div id="phone-transform">
            <TiltedPhone id="svg-phone"/>
        </div>
    </div>
    <style>{`
        #hero {
            overflow: hidden;
            height: 1024px;
            display: flex;
            padding-top: 192px;
            max-height: 100vh;
        }
        #hero .wrapper {
            min-height: 512px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
        #hero .tagline {
            grid-column: 1/3;
        }
        #hero .buttonArea { grid-column: 1/3; }
        #hero #phone-background { grid-column: 2/4; }
        #hero .tagline, #hero .buttonArea {
            transform: translateY(13vh);
        }
        #phone-transform {
            width: 100%;
            transform-origin: top left;
            animation: svg-phone 2s cubic-bezier(.51,.8,.24,1) forwards;
            align-self: flex-end;
        }
        #ui-transform {
            position: relative;
            z-index: 3;
            width: 100%;
            transform-origin: top left;
            animation: svg-ui 3s cubic-bezier(.45,1.55,.26,1.01) forwards;
            align-self: flex-end;
        }
        #phone-background {
            position: relative;
            z-index: 0;
            margin-Top: -38vh;
        }
        @keyframes svg-phone {
            0% { opacity: 0; transform: translate(32px,192px) rotateZ(20deg) skewX(20deg) scaleY(0.68); }
            30 { opacity: 1; }
            100% { transform: none; }
        }
        @keyframes svg-ui {
            0% { opacity: 0; }
            20% { opacity: 0; transform: translate(32px,64px); }
            60% { opacity: 1; }
            100% { transform: none; }
        }
        @media only screen and (max-width: 1440px) {
            #hero .tagline, #hero .buttonArea {
                transform: translateY(8vh);
            }
            #hero .buttonArea {
                padding-top: 64px;
            }
        }
        @media only screen and (max-width: 1088px) {
            #hero {
                text-align: center;
                max-height: unset;
                height: unset;
                padding-top: 192px;
            }
            #hero .tagline, #hero .buttonArea {
                transform: none;
            }
            #hero .buttonArea {
                padding-top: 0;
            }
            #phone-background {
                margin-Top: 64px;
                position: relative;
                height: unset;
                padding: 64px 0;
            }  
            #hero .wrapper {
                display: flex;
            }
            #hero .buttonArea { order: 3; }
        
        }
    `}</style>
</Section>
}

export function ProgramLaunch(props) {
    return <><Section className={`center gray`} style={{...props.style}}>
        <Icon w="128" h="128" src="beta-logo-app.svg" className="standby fade-in" style={{borderRadius:"23%",boxShadow:"0 16px 48px -16px var(--dusk)"}} />
        <Space h="48"/>
        <div className="standby subtle-in delay3 flex row">
            <Icon w="32" h="32" src="date-black.svg" />
            <Space w="12"/>
            <Text size="L" className="skinny">Fall 2022</Text>
        </div>
        <div className="slim">
            <AnimatedText
                className="zoom"
                text="Exclusive Program Launching Soon"
                size = "XXXL"
                class = "accentFont"
            />
        </div>
        <Space h="48"/>
        <a href="/start/" className="standby fade-in button black">
            <div className="flex row">
                <Icon w="24" h="24" src="plus-white.svg" style={{transform:"translateX(-6px)"}}/>
                <Text>Join limited waitlist</Text>
            </div>
        </a>
    </Section>
    <div className="transition dark"/>
    </>
}

export function SecondarySignup(props) {
    return <><Section className={`center blue`} style={{...props.style}}>
        <Icon w="128" h="128" src="beta-logo-app.svg" className="standby fade-in" style={{borderRadius:"23%",boxShadow:"0 16px 32px -8px #2D2FC3"}} />
        <Space h="32"/>
        <div className="slim">
            <AnimatedText
                className="zoom"
                text="Find your next interpreter in seconds"
                size = "XXXL"
                class = "accentFont"
            />
        </div>
        <Space h="48"/>
        <a href="/start/" className="standby fade-in button white">
            <div className="flex row">
                <Text>Get started</Text>
                <Icon w="24" h="24" src="start-black.svg" style={{transform:"translateX(6px)"}}/>
            </div>
        </a>
    </Section>
    <div className="transition dark"/>
    </>
}

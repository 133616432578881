import { Link } from 'react-router-dom';
import { Nav,Bot,Section,Space,Photo,Icon,Illustration,PhotoAspect,Text,AnimatedText } from '../Symbols.js'
import { SecondarySignup } from '../Banners'

export default (props)=>{
    return <>
        <Nav />
        <Section className="shortPage center" wrapperClass="slim">

            <Space h="64"/>
            <Illustration className="ready standby move-down delay1" w="100%" maxW="512" aspect="0.6" src="plan.svg"/>
            <Space h="32"/>
            <div className="narrow">
                <AnimatedText
                    className="zoom center"
                    text="Secret Plan"
                    size = "XXL"
                />
            </div>
            
        </Section>
        <Section className="center noTopPad noBotPad" wrapperClass="sli">
                
            <div className="ready standby delay3 timelineLine gray" style={{height:"128px"}}/>

            <div className="center group slim flex">
                {[
                    {status:"Y",date:"6-12-21",title:"Create Website"},
                    {status:"Y",date:"6-20-21",title:"Register business & licenses"},
                    {status:"N",title:"Generate revenue to develop app"},
                    {status:"N",title:"Launch iOS/Android App"},
                    {status:"N",title:"Expand nationwide"},
                    {status:"N",title:"Create new jobs"},
                    {status:"N",title:"Develop Sign-to-Text Technology"},
                    {status:"N",title:"Expand platform to include more languages"},
                    {status:"N",title:"Launch Sign-to-Text App"},
                    {status:"N",title:"Expand globally"},
                    {status:"N",title:"Develop Augmented/Virtual Reality for Interpreting"},
                    {status:"N",title:"Collaborate with Neuralink"},
                ].map((item, i)=>{
                    return <div className="group slim flex">
                        <Icon w="48" h="48" src={item.status=="Y"?"timeline-now.svg":"timeline-later.svg"} className="standby"/>
                        <Space h="8"/>
                        <Text size="XL" className="standby">
                            {item.title}
                        </Text>
                        <Text className="standby dusk">
                            {item.date}
                        </Text>
                        <Space h="8"/>
                        <div className="standby timelineLine gray"/>
                </div>
                })}
            </div>


        </Section>

        <Section className="center" style={{
            width:"100%",
            overflow:"hidden",
            zIndex:"2",
            height:"384px",
            background:"linear-gradient(0deg,var(--black),var(--blue),var(--sky),var(--white))",
        }}>
            <AnimatedText
                className="zoom center bounce"
                text="Together, our future is bright"
                size = "XXL"
                class="white"
            />
        </Section>
        

        <Bot />
    </>
}
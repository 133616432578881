import { Link } from 'react-router-dom';
import { Nav,Bot,Section,Text,AnimatedText,Space } from '../Symbols.js'
import { ProgramLaunch } from '../Banners'

export default (props)=>{
    return <>
        <Nav className="float"/>
        <Section className="shortPage center">
            <Space h="128"/>
            <Text className="standby ready fade-in">Please contact</Text>
            <div className="standby ready fade-in delay1">
                <a href="mailto:book@interpreting.app">
                    <Text size="XXL" className="blue">book</Text>
                    <Text size="XXL" className="blue">@</Text>
                    <Text size="XXL" className="blue">interpreting.app</Text>
                </a>
            </div>
            <Text className="standby ready fade-in delay2">for further assistance</Text>
        </Section>
        <Section className="short center gray">
            <Text className="dusk">Get notified of our App release</Text>
        </Section>
        <ProgramLaunch style={{paddingTop:"0"}}/>
        <Bot/>
    </>
}
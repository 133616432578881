import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import {Helmet} from "react-helmet";
import {Text,Icon} from './Symbols'

import firebase from 'firebase/app'
import 'firebase/analytics'

import NotFound from './pageHeaders/NotFound'
import Home from './pageHeaders/Home'
import Contact from './pageHeaders/Contact'
import About from './pageHeaders/About'
import Privacy from './pageHeaders/Privacy'
import Deaf from './pageHeaders/Deaf'
import Interpreters from './pageHeaders/Interpreters'
import Organizations from './pageHeaders/Organizations'
import Book from './pageHeaders/Book'
import Start from './pageHeaders/Start'
import Safety from './pageHeaders/Safety'
import SecretPlan from './pageHeaders/SecretPlan'

import Safety_Covid from './pageHeaders/Safety_Covid'
import About_Plan from './pageHeaders/About_Plan'
import About_Letter from './pageHeaders/About_Letter'

import ScrollRestoration from 'react-scroll-restoration'

// Reset scroll on page change
var firebaseAnalytics
var standbyElements
var holdAnimations = false
var titleDelay = 2
var animationi = 0
function ResetPage() {
  let { pathname } = useLocation();
  useEffect(() => {
    // 
    holdAnimations = false
    animationi = 0
    window.scrollTo(0, 0)
    standbyElements = document.getElementsByClassName("standby")
    window.addEventListener("scroll",pageScroll)
    window.addEventListener("mousemove",resetTitle)
    pageScroll()
    titleDelay = 2
    // Analytics
    firebaseAnalytics.setCurrentScreen(window.location.pathname) // set screen_name parameter
    firebaseAnalytics.logEvent('page_view')

  }, [pathname]);
  return null;
}
function resetTitle() {
  if (titleDelay > 0) {
    titleDelay -= 1
    if (titleDelay == 0) {
      document.title = "The Interpreting App"
      window.removeEventListener("mousemove",resetTitle)
    }
  }
}
function pageScroll() {
  resetTitle()
  if (window.pageYOffset > 10 && holdAnimations) {
    return
  } else {
    holdAnimations = false
  }
  if (animationi >= standbyElements.length) {
    window.removeEventListener("scroll",pageScroll)
    return
  }
  while (true) {
    if (animationi >= standbyElements.length) {
      break
    }
    let element = standbyElements[animationi]
    if ( element.getBoundingClientRect().top < window.innerHeight - window.innerHeight*0.16 ) {
      element.classList.add("ready")
      animationi += 1

    } else {
      break
    }
  }

}

function App() {
  return (<>
    <Router>
      <ResetPage/>
      <ScrollRestoration/>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/contact/" component={Contact}/>
        <Route exact path="/about/" component={About}/>
        <Route exact path="/privacypolicy/" component={Privacy}/>
        <Route exact path="/deaf/" component={Deaf}/>
        <Route exact path="/interpreters/" component={Interpreters}/>
        <Route exact path="/organizations/" component={Organizations}/>
        <Route exact path="/book/" component={Book}/>
        <Route exact path="/start/" component={Start}/>
        <Route exact path="/safety/" component={Safety}/>
        <Route exact path="/secretplan/" component={SecretPlan}/>

        <Route exact path="/safety/covid19/" component={Safety_Covid}/>
        <Route exact path="/about/plan/" component={About_Plan}/>
        <Route exact path="/about/letter/" component={About_Letter}/>
        
        <Route component={NotFound}/>
      </Switch>
    </Router>
  </>);
}

export default App;

var firebaseConfig = {
  apiKey: "AIzaSyBkna4Hdsx5ULXlK20yJJAqd3SijMYJOGY",
  authDomain: "theinterpretingapp.firebaseapp.com",
  projectId: "theinterpretingapp",
  storageBucket: "theinterpretingapp.appspot.com",
  messagingSenderId: "388352567867",
  appId: "1:388352567867:web:39130aa313a01538cb8a1e",
  measurementId: "G-L3W5XQZE35"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebaseAnalytics = firebase.analytics()
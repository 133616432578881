import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';

export function Nav(props) {
    const [expandNav, setExpandNav] = useState(false);
    const toggleNav = () => {setExpandNav(!expandNav);}
    return <Section id="nav" className={`${expandNav?'expand ':''}${props.light?'light ':''}${(props.className)?props.className:""}`} wrapperClass="flex">
        <div className="flex row group">
            <Link to="/" className="brand">
                <Icon src={`beta-logo-text${(props.light &&! expandNav)?'':'-black'}.svg`} w="200" h="60"/>
            </Link>
            <Link to="/about/" className="desktop item">
                <Text>About</Text>
            </Link>
            <Space w="32" className="desktop"/>
            <Link to="/book/" className={`desktop item button ${props.light?'white':''}`}>
                <div className="flex row">
                    <Text>Book Now</Text>
                    <Icon w="24" h="24" src={`arrow-right-${props.light?'blue':'black'}.svg`} style={{marginRight:"-8px"}}/>
                </div>
            </Link>
            <div className="mobile item">
                <div id="navToggle" onClick={toggleNav}>
                    <Icon src={expandNav?'exit.svg':'menu.svg'} w="36" h="36"/>
                </div>
            </div>
        </div>
        <div className="more group flex">
            <Link to="/about/" className="item">
                <Text>About</Text>
            </Link>
            <Link to="/contact/" className="item">
                <Text>Contact</Text>
            </Link>
            <Space w="16" className="desktop"/>
            <Link to="/book/" className={`item button ${(props.light && !expandNav)?'white':''}`}>
                <Text>Book Now</Text>
            </Link>
        </div>
    </Section>
}

export function Bot(props) {
    return <Section id="bot" className="smallFont short" wrapperClass="grid split2x512">
        <div>
            <div className="xB group flex row left">
                <Link to="/">
                    <Icon src={`beta-logo-text.svg`} w="200" h="60"/>
                </Link>
                <Space w="32" h="32"/>
                <Link to="/about/"><Text className="white">About</Text></Link>
                <Space w="16"/>
                <Link to="/contact/"><Text className="white">Contact</Text></Link>
            </div>
            <Space h="32"/>
            <div className="group flex row left">
                <a target="noreferer" href="https://www.facebook.com/interpretingapp">
                    <Icon src="facebook.svg" w="28" h="28"/>
                </a>
                <a target="noreferer" href="https://www.instagram.com/theinterpretingapp/">
                    <Icon src="instagram.svg" w="28" h="28"/>
                </a>
                <a target="noreferer" href="https://www.linkedin.com/company/theinterpretingapp/">
                    <Icon src="linkedin.svg" w="28" h="28"/>
                </a>
            </div>
        </div>
        <div>
            <div className="xA group flex row left" style={{whiteSpace:"nowrap",minHeight:"76px"}}>
                <Link to="/deaf/"><Text>for Deaf</Text></Link>
                <Space w="16"/>
                <Link to="/interpreters/"><Text>for Interpreters</Text></Link>
                <Space w="16"/>
                <Link to="/organizations/"><Text>for Organizations</Text></Link>
            </div>
        </div>
        <div className="group">
            <div><Icon src="copyright-gray.svg" w="18" h="18" style={{margin:"8px",marginLeft:"0"}}/><Text>Copyright {(new Date()).getFullYear()}. All Rights Reserved.</Text></div>
            <div><Text>The Interpreting App, LLC.</Text></div>
        </div>
        <div className="group">
            <div>
                <Link to="/privacypolicy/"><Text>Privacy Policy</Text></Link>
                <Space h="32"/>
            </div>
        </div>
    </Section>
}

export function Section(props) {
    return <div id={props.id} className={`section ${(props.className)?props.className:""}`} style={props.style}>
        <div className={"wrapper group "+props.wrapperClass}>
            {props.children}
        </div>
    </div>
}

export function Text(props) {
    return <div id={props.id} className={"text "+props.size+" "+props.className} style={props.style}>
        {props.children}
    </div>
}

export function Space(props) {
    let style = {
        width:`${props.w?props.w:'0'}px`,
        height:`${props.h?props.h:'0'}px`,
        ...props.style
    }    
    return <div id={props.id} className={"space "+props.className} style={style}>
    </div>
}

export function Icon(props) {
    let style = {
        backgroundImage:`url(/static/icons/${props.src})`,
        width:`${props.w}px`,
        height:`${props.h}px`,
        ...props.style
    }    
    return <div id={props.id} className={`icon ${(props.className)?props.className:""}`} style={style}>
    </div>
}

export function Illustration(props) {
    let style = {
        backgroundImage:`url(/static/icons/${props.src})`,
        width:`${props.w}`,
        maxWidth:`${props.maxW}px`,
        ...props.style
    }    
    return <div id={props.id} className={`illustration ${(props.className)?props.className:""}`} style={style}>
        <div style={{paddingBottom:`${props.aspect*100}%`}}/>
    </div>
}

export function PhotoAspect(props) {
    let style = {
        backgroundImage:`url(/static/photos/${props.src})`,
        width:`${props.w}`,
        maxWidth:`${props.maxW?props.maxW:1288}px`,
        ...props.style
    }    
    return <div id={props.id} className={`aspectPhoto ${(props.className)?props.className:""}`} style={style}>
        <div style={{paddingBottom:`${props.aspect*100}%`}}/>
    </div>
}

export function Photo(props) {
    let style = {
        width:`${props.w}`,
        height:`${props.h}`,
        ...props.style
    }
    return <div id={props.id} className={`photo ${(props.className)?props.className:""}`} style={style}>
        <div className="thumbnail" style={{
            backgroundImage:(props.thumbnail)?`url(data:image/png;base64,${props.thumbnail})`:"",
            width:"100%",height:"100%",
        }}></div>
        <div className="data" style={{
            zIndex:"1",
            backgroundImage:`url(/static/photos/${props.src})`,
            width:"100%",height:"100%",
        }}></div>
    </div>

}

export function AnimatedText(props) {
    return <div className={`animatedText standby ${props.className}`} style={props.style}>
        {props.text.split(" ").map((e,i)=>{
            return <Text key={"at"+i} className="animatedLetter">
                <Text style={{animationDelay:0.1+i*.06+"s"}} className={props.class} size={props.size}>{e}&nbsp;</Text>
            </Text>
        })}
    </div>
}
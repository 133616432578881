import { Link } from 'react-router-dom';
import { Nav,Bot,Section,Text,AnimatedText,Space } from '../Symbols.js'

export default (props)=>{
    return <>
        <Nav className="float"/>
        <Section className="page center">
            <Space h="64"/>
            <Text size="XXXL" className="accentFont blue">404</Text>
            <Space h="32"/>
            <AnimatedText
                className="zoom"
                text="Page Not Found"
                size = "XXL"
                class = "accentFont"
            />
            <Space h="64"/>
            <Text className="dusk">Sorry, this page does not exist or was deleted.</Text>
            <Text className="dusk" >If you believe this is an issue, please <Link to="/contact/">contact us</Link>.</Text>
        </Section>
        <Bot/>
    </>
}
import { Link } from 'react-router-dom';
import { Nav,Bot,Section,Space,Icon,Text,AnimatedText } from '../Symbols.js'

export default (props)=>{
    return <>
        <Nav className="float"/>
        <Section className="page center">
            <Space h="128"/>
            <AnimatedText
                text="We're here to help"
                size = "XXXL"
                class = "accentFont blue"
            />
            <Space h="64"/>
            <div className="grid split2x512" style={{rowGap:"64px"}}>
                <div className="standby ready fade-in delay2">
                    <a href="mailto:support@interpreting.app" className="group flex">
                        <Icon w="32" h="32" src="email-blue.svg"/>
                        <Space h="8"/>
                        <Text size="L" className="left">support@interpreting.app</Text>
                    </a>
                </div>
                <div className="standby ready fade-in delay3">
                    <a href="mailto:hello@interpreting.app" className="group flex">
                        <Icon w="32" h="32" src="email-blue.svg"/>
                        <Space h="8"/>
                        <Text size="L" className="left">hello@interpreting.app</Text>
                    </a>
                </div>
                <div className="standby ready fade-in delay3">
                    <a href="tel:949-529-0538" className="group flex">
                        <Icon w="32" h="32" src="phone-blue.svg"/>
                        <Space h="8"/>
                        <Text size="L" className="left">(949) 529 0538</Text>
                    </a>
                </div>
                <div className="standby ready fade-in delay4">
                    <div className="group flex">
                        <Icon w="32" h="32" src="storefront-blue.svg"/>
                        <Space h="8"/>
                        <Text size="L" className="left">7130 Magnolia Ave Ste L<br/>Riverside CA 92504</Text>
                    </div>
                </div>
            </div>
        </Section>
        <Bot/>
    </>
}
import { Link } from 'react-router-dom';
import { Nav,Bot,Section,Space,Photo,Icon,PhotoAspect,Text,AnimatedText } from '../Symbols.js'
import { SecondarySignup } from '../Banners'

export default (props)=>{
    return <>
        <Nav />
        <Section className="page center" wrapperClass="slim">

            <Space h="96"/>

            <Text className="standby subtle-in delay2 dusk">June 2021</Text>
            <div className="narrow">
                <AnimatedText
                    className="center"
                    text="A letter from Jacob Lopez"
                    size = "XXL"
                />
            </div>
            <Text className="standby delay2 blue">Chief Executive Officer</Text>
            <Space h="64"/>
            <Photo src="jacob.png" w="100%" h="512px" className="standby delay3"/>
            
            <Space h="64"/>
            
            <div className="center groupParagraph slim flex top alignTextLeft">
                <Text size="XL" className="standby">
                    A life mission towards employment, education & technology.
                </Text>
                <Space h="16"/>
                <Text className="standby indent">
                    The first video game I ever played was Nanosaur - a science fiction game where you fly around the world as a genetically engineered intelligent dinosaur from the future, sent back in time just before the extinction event.
                    <br/>I must have been around 5 years old, playing on the first ever, iMac.
                </Text>
                <Text className="standby indent">
                    From that point, I became hooked on desktop computers.
                </Text>
                <div className="standby grid split2x512">
                    <Photo w="100%" h="384px" src="jacob-child.png" className="shadow"/>
                    <Text className="indent">
                        At around 7 years old, I created my first business venture by creating a series of online video game servers where players could join my community to play and make friends. I created a website & forum for my gaming community and marketed it everywhere I could in order to recruit players, moderators, administrators, developers and even implemented a subscription model to allow players to have 'special' in-game abilities after a monetary donation.
                    </Text>
                </div>
                <Text className="standby indent">
                    I had a lot of other ideas during this age that I would also bring to fruition.
                </Text>
                <Text className="standby indent">
                    Such as selling my marketing 'skills' as a service to other video gamers online to help them garner more views/visitors, or charging my school friends $20 bucks to 'boost' their home computers with my favorite special softwares to help their computers run faster and perform smoother. If they were skeptical, all I had to do was show my 4th grade friends my website OR type 140 words per minute and they were astonished!
                </Text>

                <Space h="32"/>

                <PhotoAspect w="100%" maxW="200px" aspect="0.415" src="img8.png" className="standby shadow"/>

                <Space h="64"/>

                <Text className="standby indent">
                    My first "real world" idea wasn't until I graduated high school and moved to Washington, D.C.
                </Text>
                <Text className="standby indent">
                    My oldest brother Chris is deaf and he was studying at Gallaudet University which is the world's leading university for the Deaf and Hard of Hearing (located in D.C.). At the time he would constantly ask me to order take-out food for him over the phone and in return for making the phone call, he would offer to buy me some food also. After a few weeks, I realized there were at least a couple thousand students at Gallaudet. In my mind, who also likely experienced similar critical communication issues for something as simple as ordering take-out food.
                </Text>
                <Text className="standby indent">
                    I consulted with my mom, an interpreter, about this problem and she pitched me the idea to create an online food ordering website. So I did!
                </Text>
                <Text className="standby indent">
                    I bicycled around D.C. and knocked on all of my brother's favorite restaurants and no owner would give me any of their precious time at first, until I was persistent and had the realization that they must have thought of me as a pesty salesperson. The next time I showed up, I handed them a $30 tablet (which I convinced my parents to buy me from Walmart) and they asked me what it was for?
                    <br/>I asked them, "Do you know Gallaudet University?". To which they replied, "Oh sure! The school down the street with all of the deaf students!".
                    <br/>I asked them if they ever realized - its virtually impossible to order take-out over the phone for them. It was always up to me, a hearing person, to do it.
                </Text>
                <Text className="standby indent">
                    This finally opened up the conversation and soon I was signing up every local businesses onto my website, quickly.
                </Text>
                <Text className="standby">
                    So that's all I want to do with my life.
                </Text>
                <Text className="standby">
                    I want to open up these doors. Kick them down! If I can!
                </Text>
                <Text className="standby indent">
                    I promote entrepreneurship in the deaf community.
                    <br/>I can see CEO's and leaders in the world who come from our cherished deaf community. I see them with their fancy, executive interpreters on private jets and tours around the globe, leading and inspiring change.
                </Text>
                <Text className="standby indent">
                    I advocate towards issues in health care because of the issues our deserving community faces with something as simple as food ordering! Just imagine the issues we have with healthcare?
                    
                </Text>
                <Space h="32"/>
                <div className="standby card group flex top" style={{padding:"16px"}}>
                    <Text>Let's do some quick math.</Text>
                    <Text>If there are 400,000 deaf people in America, and only 20% of them went to the doctor this year... that's 80,000 people. Now, if only 5% of those 80,000 people experienced a no-show interpreter for the visit, that's 4,000 people or 333+ people a month <Text className="bold noindent">without</Text> an interpreter.</Text>
                    <Text>Wow.</Text>
                </div>
                <Space h="32"/>
                <Text className="standby indent">
                    I am promoting awareness towards education reform to achieve a revitalization towards deaf education in America that will reinforce literacy skills of deaf people in our country. Learning a written language without being able to hear is very difficult and this proves to be difficult when trying to join the workforce. I believe reinforcing deaf education also reinforces employment.
                </Text>
                <Text className="standby indent">
                    In my humble opinion, this is something worth spending a lifetime advocating for.
                </Text>
                <Text className="standby indent">
                    Last but not least, to those remarkable ones who are hearing in a deaf family–please <a className="noindent" href="mailtp:jacob@interpreting.app">contact me</a>.
                </Text>
                <Text className="standby">
                    The world needs you.
                </Text>
                <Text className="blue standby accentFont" size="L">
                    Jacob Lopez
                </Text>
                
            </div>


        </Section>
        
        <div style={{
                width:"100%",
                overflow:"hidden",
                zIndex:"2",
                height:"384px",
                background:"linear-gradient(0deg,var(--black),var(--blue),var(--sky),var(--white))",
                // margin:"-64px 0",
                // filter:"blur(16px)"
            }}>
        </div>

        <Bot />
    </>
}